<template>
  <div class="orderInfo">
    <div class="title">买家信息</div>
      <el-form :model="info" ref="form"  size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item prop="userName" label="昵称">
              <div> {{info.buyerName}} </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="receivePhone" label="联系电话">
              <div> {{info.receivePhone}} </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="orderId" label="订单ID" width="100"></el-table-column> -->
        <el-table-column prop="cover" label="宝贝">
          <template slot-scope="scope">
            <el-image
              style="width:50px; height:50px;"
              :src="scope.row.skuPicture"
              fit="cover"
              :preview-src-list="[scope.row.skuPicture]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="itemName" label="商品名称"></el-table-column>
        <el-table-column prop="skuName" label="商品规格"></el-table-column>
        <el-table-column prop="skuSize" label="商品尺寸"></el-table-column>
        <el-table-column prop="orderStatusText" label="状态"></el-table-column>
        <el-table-column prop="itemPrice" label="单价(元)"></el-table-column>
        <el-table-column prop="itemNum" label="数量"></el-table-column>
        <el-table-column prop="totalDiscount" label="优惠"></el-table-column>
        <el-table-column prop="totalPrice" label="商品总价(元)"></el-table-column>
        <el-table-column prop="freight" label="运费(元)"></el-table-column>
      </el-table>
      <div class="total-box">
        <span>实际收款： <span class="price">{{info.payPrice}}</span> 元</span>
      </div>
      <el-form :model="info" ref="form" label-width="120px" size="mini" style="margin-top:20px;">
        <el-form-item prop="orderId" label="订单编号">
          <div> {{info.orderId}} </div>
        </el-form-item>
        <el-form-item prop="payType" label="付款方式">
          <div> {{payTypeKeys[info.payType]}} </div>
        </el-form-item>
        <el-form-item prop="createTime" label="成交时间">
          <div> {{info.createTime}} </div>
        </el-form-item>
        <el-form-item prop="payTime" label="付款时间">
          <div> {{info.payTime}} </div>
        </el-form-item>
        <el-form-item prop="finishTime" label="确认时间">
          <div> {{info.finishTime}} </div>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
export default {
  name: 'orderInfo',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    tableData(){
      const item = {
        skuPicture: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
        name: 'evan',
        ...this.info,
      }
      return [item]
    },
  },
  data(){
    return{
      payTypeKeys: {
        '1': '微信',
        '2': '支付宝',
        '3': '余额',
        '4': '线下支付'
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.orderInfo{
  padding: 0 20px;
  .title{
    font-size: 14px;
    font-weight: 600;
  }
  .total-box{
    border-bottom:1px solid #EBEEF5;
    height:50px;
    line-height: 50px;
    text-align:right;
    padding-right: 10px;
    .price{
      font-size: 16px;
      font-weight: bold;
      color: #f50;
      padding-right: 5px;
    }
  }
}
</style>

<style lang="scss">
.orderInfo{
  .el-form .el-form-item {
    margin-bottom: 3px !important;
  }
  .el-table__header th{
    border-bottom: 1px solid #ddd !important;
    // background-color: #EEF2FE !important;
    background-color: #e8f2ff !important;
  }
}
</style>

